import React, { useState } from "react"
import PropTypes from "prop-types"
import Input from "../../../../components/input"

const ValidatableInput = ({
  id,
  solution,
  messageCorrect,
  messageIncorrect,
  checkCase,
  value,
  ...restProps
}) => {
  const [isValid, setIsValid] = useState(null)
  const [inputValue, setInputValue] = useState(value)

  const handleChange = (event) => {
    setInputValue(event.target.value)
    validate({ input: event.target.value })
  }

  const validate = ({ input }) => {
    const sanatizedInput = checkCase
      ? input?.trim()
      : input?.toLowerCase().trim()
    const sanatizedSolution = checkCase ? solution : solution.toLowerCase()
    if (sanatizedInput && sanatizedInput === sanatizedSolution) {
      setIsValid(true)
      return
    }

    setIsValid(false)
  }

  return (
    <Input
      id={id}
      {...restProps}
      tone={isValid !== null ? (isValid ? "positive" : "negative") : undefined}
      value={inputValue}
      onChange={handleChange}
      message={
        isValid !== null
          ? isValid
            ? messageCorrect
            : messageIncorrect
          : undefined
      }
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault()
          validate({ input: event.target.value })
        }
      }}
      onBlur={(event) => {
        event.preventDefault()
        validate({ input: event.target.value })
      }}
    />
  )
}

ValidatableInput.propTypes = {
  id: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
  messageCorrect: PropTypes.string,
  messageIncorrect: PropTypes.string,
  checkCase: PropTypes.bool,
}

ValidatableInput.defaultProps = {
  messageCorrect: "Ganz genau!",
  messageIncorrect: "Leider falsch.",
  checkCase: false,
}

export default ValidatableInput
